<template>
    <v-content>
        <v-container fluid>
            <!-- <v-row no-gutters>
        <v-col cols="10"></v-col>
        <v-col cols="2">
            <v-btn cols="2" class="success" @click.stop="onDownload">
                <v-icon>get_app</v-icon>
                Export
            </v-btn>
        </v-col>
    </v-row>-->
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <div>
                                <v-row align="start" justify="start" class="mt-4">

                                    <v-col class="d-flex flex-sm-row flex-column" cols="12" sm="4">
                                        <v-autocomplete v-model="serverParams.ReportType"
                                                        :items="ReportTypes"
                                                        :label="$t('report_type')"
                                                        clearable
                                                        outlined
                                                        dense
                                                        single-line
                                                        item-text="value"
                                                        item-value="value"></v-autocomplete>
                                    </v-col>
                                    <v-col class="d-flex flex-sm-row flex-column" cols="12" sm="4">
                                        <v-autocomplete v-model="serverParams.ReportMode"
                                                        :items="ReportModes"
                                                        :label="$t('report_category')"
                                                        clearable
                                                        outlined
                                                        dense
                                                        single-line
                                                        item-text="value"
                                                        item-value="value"></v-autocomplete>
                                    </v-col>
                                    <v-col class="d-flex flex-sm-row flex-column" cols="12" sm="2">
                                        <v-select :items="customYear"
                                                  :label="$t('select_year')"
                                                  outlined
                                                  dense
                                                  v-model="serverParams.FromDate"
                                                  prepend-inner-icon="event"></v-select>
                                    </v-col>
                                    <!--<v-col class="d-flex mt-4" cols="12" sm="2" v-if="reportByMonth">
                                <v-select
                                          :items="customMonth"
                                          :label="$t('select_month')"
                                          v-model="serverParams.ToDate"
                                          prepend-icon="event"
                                          item-text="value"
                                          item-value="id"
                                          dense></v-select>
                            </v-col>-->
                                    <!--<v-select v-if="reportByMonth"
                                  class="mt-8"
                                  :items="customMonth"
                                  :label="$t('select_month')"
                                  v-model="serverParams.ToDate"
                                  prepend-icon="event"
                                  item-text="value"
                                  item-value="id"
                                  dense></v-select>-->
                                    <!--<v-col class="d-flex" cols="12" sm="3">
                                <v-menu v-model="snackbar.menu1"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        :close-on-content-click="false"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="serverParams.FromDate"
                                                      :label="$t('from_date')"
                                                      clearable
                                                      prepend-icon="event"
                                                      readonly
                                                      v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="serverParams.FromDate">

                                    </v-date-picker>
                                </v-menu>
                            </v-col>-->

                                    <v-col class="d-flex flex-sm-row flex-column" cols="12" sm="3" v-if="!reportIsMonthWise">
                                        <v-menu v-model="snackbar.menu2"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field v-model="serverParams.ToDate"
                                                              :label="$t('to_date')"
                                                              clearable
                                                              prepend-icon="event"
                                                              readonly
                                                              v-on="on"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.ToDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex flex-sm-row flex-column" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click.stop="loadItems">
                                            <v-icon dark> search </v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <!--<v-btn @click.stop="onDownload" small class="success mx-4">
                                    <v-icon>get_app</v-icon>
                                    {{$t('download_to_excel')}}
                                </v-btn>-->
                                        <JsonExcelVue :fetch="fetchData" is-custom-header :customHeader="customHeader">
                                            <v-btn small class="success mx-4">
                                                <v-icon>get_app</v-icon>
                                                {{$t('download_to_excel')}}
                                            </v-btn>

                                        </JsonExcelVue>
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <div class="d-flex justify-center" v-if="isLoading">
                <v-progress-circular :size="50"
                                     color="primary"
                                     indeterminate></v-progress-circular>

            </div>
            <v-simple-table v-if="table1" class="table tbl">
                <thead>
                    <tr>
                        <th rowspan="2"> {{$t('month')}}</th>
                        <th colspan="3"> {{$t('flight_movement')}} </th>
                        <th colspan="3"> {{$t('passenger_movement')}}</th>
                        <th v-if="domesticReportModes == false" rowspan="2"> {{$t('transit')}}</th>
                    </tr>
                    <tr>
                        <th>{{$t('departure')}}</th>
                        <th>{{$t('arrival')}}</th>
                        <th>{{$t('total')}}</th>
                        <th>{{$t('out')}}</th>
                        <th>{{$t('in')}}</th>
                        <th>{{$t('total')}}</th>
                    </tr>
                </thead>
                <tbody>

                    <tr v-for="row in rows" :key="row">
                        <td>{{row.month}}</td>
                        <td>{{row.dep}}</td>
                        <td>{{row.arr}}</td>
                        <td>{{row.totalFlight}}</td>
                        <td>{{row.in}}</td>
                        <td>{{row.out}}</td>
                        <td>{{row.totalPassenger}}</td>
                        <td v-if="domesticReportModes == false">{{row.transit}}</td>
                    </tr>

                </tbody>
            </v-simple-table>

            <v-simple-table v-else-if="table2" class="table tbl">
                <thead>
                    <tr>
                        <th rowspan="2"> {{$t('airline')}} </th>
                        <th colspan="3"> {{$t('flight_movement')}} </th>
                        <th colspan="3"> {{$t('passenger_movement')}}</th>
                        <th v-if="domesticReportModes == false" rowspan="2"> {{$t('transit')}}</th>
                    </tr>
                    <tr>
                        <th>DEP</th>
                        <th>ARR</th>
                        <th>{{$t('total')}}</th>
                        <th>{{$t('out')}}</th>
                        <th>{{$t('in')}}</th>
                        <th>{{$t('total')}}</th>
                    </tr>
                </thead>
                <tbody>

                    <tr v-for="row in rows" :key="row">
                        <td>{{row.airLines}}</td>
                        <td>{{row.dep}}</td>
                        <td>{{row.arr}}</td>
                        <td>{{row.totalFlight}}</td>
                        <td>{{row.in}}</td>
                        <td>{{row.out}}</td>
                        <td>{{row.totalPassenger}}</td>
                        <td v-if="!domesticReportModes">{{row.transit}}</td>
                    </tr>

                </tbody>

            </v-simple-table>

            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
        <v-row class="mt-7">
            <div class="col-12" style="color: gray; text-align: right">
                {{$t('source')}} : {{$t('department_of_immigration')}}
            </div>
        </v-row>
    </v-content>
</template>

<script>
    import axios from "axios";
    import JsonExcelVue from "@/components/ImportExport/JsonExcel.vue"
    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        components: {
            JsonExcelVue
        },
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
            reportByYear() {
                return this.serverParams.ReportType === 'yearwise';
            },
            reportByMonth() {
                return this.serverParams.ReportType === 'monthwise';
            },
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise" || "AirlinewiseMovement";
            },
            items() {
                return [
                    {
                        text: this.$t('report'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('tourist_arrival_report_menu'),
                        exact: true,
                        to: '/reportModules/reportTourist-Arrival'
                    },
                    {
                        text: this.$t('passenger_movement'),
                        disabled: true,
                        exact: true
                    }
                ]
            }
        },
        methods: {
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            formResponse(data) {
                console.log({data})
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onDownload() {
                let param = {
                    ReportType: this.serverParams.ReportType,
                    ReportMode: this.serverParams.ReportMode,
                    FromDate: this.serverParams.FromDate,
                    ToDate: this.serverParams.ToDate,
                };
                if (this.serverParams.ReportType === 'monthwise') {
                    await axios({
                        url: 'Immigration/DownloadFlightAndPassengerMovementReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'FlightAndPassengerMovementReportListAsyc.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
                }
                else if (this.serverParams.ReportType === 'yearwise') {
                    await axios({
                        url: 'Immigration/DownloadYearlyFlightAndPassengerMovementReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        console.log('data', response)
                         this.excelData.json_fields['Mountain'] = 'Mountain'
                        response.data.header.map(res => {
                            this.excelData.json_fields[res.label] = res.field
                        })
                        this.excelData.json_fields['Total'] = 'Total'
                        this.excelData.json_data = response.data.dataList
                    });
                        return this.excelData.json_data
                }
                else {
                    param = {
                        ReportType: this.serverParams.ReportType,
                        ReportMode: this.serverParams.ReportMode,
                        FromDate: this.serverParams.FromDate,
                    };
                    await axios({
                        url: 'Immigration/DownloadAirLineWiseFlightMovementReportListAsyc',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'AirLineWiseFlightMovementReportListAsyc.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
                }
                //await axios({
                //    url: 'Report/DownloadSample',
                //    method: 'POST',
                //    responseType: 'blob'
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', 'NationalParkFile.xlsx'); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();
                //});
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async fetchData() {
                await this.loadItems();
                this.customHeader = [
                   {
                        name: "Month"
                   },
                   {
                       name: "FLIGHT MOVEMENT",
                       children: ["DEP", "ARR", "TOTAL"]
                   },
                   {
                       name: "PASSENGER MOVEMENT",
                       children: ["OUT", 'IN', 'TOTAL']
                    },
                    {
                        name: "TRANSIT"
                    }
                ]

               return this.rows
            },
            async loadItems() {

                if(this.serverParams.ReportMode == "Domestic"){
                    this.domesticReportModes = true
                }
                else{
                    this.domesticReportModes = false
                }
                let param = {
                    ReportType: this.serverParams.ReportType,
                    ReportMode: this.serverParams.ReportMode,
                    FromDate: this.serverParams.FromDate,
                    ToDate: this.serverParams.ToDate,
                };
                let url = ""
                this.rows = []
                if (this.serverParams.ReportType == "monthwise") {
                    this.isLoading = true;
                    this.table1=true
                    this.table2=false
                    url = 'Immigration/GetFlightAndPassengerMovementReportListAsyc'
                }
                else if (this.serverParams.ReportType == "yearwise") {
                    this.isLoading = true;
                    this.table1=true
                    this.table2=false
                    url = 'Immigration/GetYearlyFlightAndPassengerMovementReportListAsyc'
                }
                else if (this.serverParams.ReportType == "AirlinewiseMovement") {
                    this.isLoading = true;
                    this.table1=false
                    this.table2 = true
                    param = {
                        ReportType: this.serverParams.ReportType,
                        ReportMode: this.serverParams.ReportMode,
                        FromDate: this.serverParams.FromDate,
                    };
                    url = 'Immigration/GetAirLineWiseFlightMovementReportListAsyc'
                }
                await axios.post(url, param)
                    .then(response => {
                        this.rows = response.data;
                        // this.totalRecords = response.data.totalCount;
                        this.columns = []
                        this.isLoading = false;
                    })
                    .catch(err => {
                        console.log(err)
                        this.isLoading = false;
                    });
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
            this.loadItems()
        },
        data() {
            return {
                domesticReportModes:false,
                table1:false,
                table2: false,
                isLoading: false,
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                customHeader:[],
                columns: [],
                rows: [],
                ReportTypes: [
                    {
                        id: "yearwise", value: "yearwise"
                    },
                    {
                        id: "monthwise", value: "monthwise"
                    },
                    {
                        id: "AirlinewiseMovements", value: "AirlinewiseMovement"
                    }
                ],
                ReportModes: [
                    {
                        id: "International", value: "International"
                    },
                    {
                        id: "Domestic", value: "Domestic"
                    }
                ],
                customMonth: [

                    {
                        id: '01',
                        value: 'january'
                    },
                    {
                        id: '02',
                        value: 'february'
                    }, {
                        id: '03',
                        value: 'march'
                    }, {
                        id: '04',
                        value: 'april'
                    }, {
                        id: '05',
                        value: 'may'
                    }, {
                        id: '06',
                        value: 'june'
                    }, {
                        id: '07',
                        value: 'july'
                    }, {
                        id: '08',
                        value: 'august'
                    }, {
                        id: '09',
                        value: 'september'
                    }, {
                        id: '10',
                        value: 'october'
                    }, {
                        id: '11',
                        value: 'november'
                    }, {
                        id: '12',
                        value: 'december'
                    },
                ],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    ReportType: "yearwise",
                    ReportMode: "Inernational"
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    /* breadcrumbsItem: [
                         {
                             text: 'Dashboard',
                             disabled: false,
                             to: 'MountaineeringDashboard',
                             exact: true

                         },
                         {
                             text: 'Mountaineering History Information',
                             disabled: true,
                         },
                     ],*/
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },
    }
</script>

<style lang="scss" scoped>

    ::v-deep table{
        th{
            color: white !important;
        }
    }
    .v-sheet--offset {
        top: -24px;
        position: relative;
    }

    .tbl {

        th {
            padding-top: 12px;
            padding-bottom: 12px;
            text-align: left;
            background-color: #1e88e5;
            color: white;
            position: sticky;
            top: 0px;
            z-index: 1;
        }
    }

    table, td, th {
        border: 1px solid #ddd;
        text-align: left;
    }

    table {
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;

    }

    th, td {
        padding: 15px;
    }

    .tfoot {
        background-color: #d2d2d2;
    }

    .data-input {
        .v-input {
            max-width: 7.1em !important;
        }
    }

    input {
        max-width: 5.7em !important;
    }

    .employee-form-header {
        background-color: #1e88e5;
        height: 3em;
        padding: .6em;

        span {
            color: #ffffff;
            font-size: 1.4em;
        }
    }

    #form_card {
        text-align: center;
    }

    .box-border {
        border: 1px solid;
        padding: 1em;
    }

</style>
