<template>
    <v-content>
        <v-container fluid>
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <div class="text-center">
                                <v-row>
                                    <v-col class="d-flex mt-5" cols="3" sm="3">
                                        <v-autocomplete v-model="serverParams.ReportType"
                                                        :items="ReportTypes"
                                                        close-on-content-click
                                                        chips
                                                        clearable
                                                        dense
                                                        outlined
                                                        color="blue-grey lighten-2"
                                                        :label="$t('report_type')"
                                                        item-text="value"
                                                        item-value="id"
                                                        @change="loadCountryNames"
                                                        class="pa-0">
                                        </v-autocomplete>


                                    </v-col>

                                    <v-col class="d-flex mt-5" cols="2" sm="2">
                                        <v-select v-if="reportIsMonthWise || reportIsYearWise || reportIsCountryWise"
                                                  :items="customYear"
                                                  dense
                                                  outlined
                                                  :label="$t('from_date')"
                                                  v-model="yearFromDate"
                                                  prepend-inner-icon="event"></v-select>
                                    </v-col>


                                    <v-col class="d-flex mt-5" cols="2" sm="2">
                                        <v-select v-if="reportIsMonthWise || reportIsCountryWise"
                                                  :items="customYear"
                                                  :label="$t('to_date')"
                                                  dense
                                                  outlined
                                                  v-model="yearToDate"
                                                  prepend-inner-icon="event"></v-select>
                                    </v-col>
                                    <v-col class="d-flex mt-5" cols="5" sm="5">
                                        <v-autocomplete v-if="reportIsCountryWise"
                                                        v-model="serverParams.CountryName"
                                                        :items="CountryNames"
                                                        close-on-content-click
                                                        chips
                                                        clearable
                                                        dense
                                                        outlined
                                                        color="blue-grey lighten-2"
                                                        :label="$t('country_name')"
                                                        item-text="value"
                                                        item-value="id">
                                        </v-autocomplete>
                                        </v-col>
                                </v-row>
                                <v-row class="mt-3" justify="center">
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark>search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <download-excel :fields="excelData.json_fields"
                                                        :fetch="onDownload"
                                                        worksheet="My Worksheet">
                                            <v-btn small class="success mx-4">
                                                <v-icon>get_app</v-icon>
                                                {{$t('download_to_excel')}}
                                            </v-btn>
                                        </download-excel>
                                    </v-col>

                                </v-row>
                            </div>
                        </v-container>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>


            <!--<vue-good-table :columns="columns"
                    :rows="rows"
                    mode="remote"
                    :isLoading.sync="isLoading"
                    styleClass="vgt-table condensed"
                    style="z-index:1"
                    max-height="500px"
                    :fixed-header="true">
        <template slot="table-column" slot-scope="props">
            <span>
                {{ $t(props.column.label) }}
            </span>
        </template>
        <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'datedOn'">
                <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
            </span>-->
            <!--<span v-else-if="props.column.field == 'actions'">
        <v-icon small class="mr-2" @click.stop="editItem(props.row, 'HistoryForm')">edit</v-icon>
        <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
    </span>-->
            <!--</template>
    </vue-good-table>-->
            <div v-if="reportIsCountryWise" class="table-container">
                <div class="d-flex justify-center" v-if="isLoading">
                    <v-progress-circular :size="50"
                                         color="primary"
                                         indeterminate></v-progress-circular>

                </div>
                <table class="myCustomTable">
                    <thead>
                        <tr>
                            <td rowspan="3">
                                Country
                            </td>
                            <td rowspan="3">
                                Trekking Route
                            </td>
                            <td rowspan="3">
                                Total
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6"> Gender</td>
                        </tr>
                        <tr>
                            <td>Male</td>
                            <td>Female</td>
                            <td>Others</td>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(row,index) in rows">
                            <tr :key="index">
                                <td>{{row.countryName}}</td>
                                <td>{{row.trekkingRoute}}</td>
                                <td>{{row.total}}</td>
                                <td>{{row.male}}</td>
                                <td>{{row.female}}</td>
                                <td>{{row.others}}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <vue-good-table :columns="columns"
                                :rows="rows"
                                mode="remote"
                                max-height="500px"
                                styleClass="vgt-table condensed">
                    <template slot="table-column" slot-scope="props">
                        <span>
                            {{ $t(props.column.label) }}
                        </span>
                    </template>
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field == 'datedOn'">
                            <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                        </span>
                        <!--<span v-else-if="props.column.field == 'actions'">
                    <v-icon small class="mr-2" @click.stop="editItem(props.row, 'HistoryForm')">edit</v-icon>
                    <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
                </span>-->
                    </template>
                </vue-good-table>
                </div>
                <v-snackbar v-model="snackbar.snackbar"
                            color="success"
                            :timeout="snackbar.timeout"
                            top>
                    {{ callbackResponse.message }}
                    <v-btn dark
                           text
                           @click="snackbar.snackbar = false">
                        Close
                    </v-btn>
                </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import downloadExcel from "vue-json-excel";
    export default {
        name: "RestrictedAreasReportList",
        props: [
            'moduleName'
        ],
        components: { downloadExcel },
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
            reportIsYearWise() {
                if (this.serverParams.ReportType == 'Year Wise') {
                    return this.serverParams.ReportType === 'ByYear';
                }
                else {
                    return this.serverParams.ReportType === 'ByMonth';
                }
            },
            reportIsMonthWise() {
                if (this.serverParams.ReportType == 'Month Wise') {
                    return this.serverParams.ReportType === 'ByMonth';
                }
                else {
                    return this.serverParams.ReportType === 'ByYear';
                }
            },
            reportIsCountryWise() {
                if (this.serverParams.ReportType == 'Country Wise') {
                    return this.serverParams.ReportType === 'ByCountry';
                }
                else {
                    return this.serverParams.ReportType === 'ByCountry';
                }
            },
        },
        methods: {
            loadCountryNames() {
                if (this.serverParams.ReportType == 'ByCountry') {
                    axios.get('Trekking/DDLCountryListAsync').then(response => {
                        this.CountryNames = response.data
                    })
                }
            },
            clearInput() {
                this.serverParams.ReportType = null
                this.yearFromDate = null
                this.yearToDate = null
            },
            changeColumn(value) {
                console.log(value)
            },
            formResponse(data) {
                console.log({ data })
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onDownload() {
                this.excelData = {
                    loading: false,
                    json_fields: {},
                    json_data: [],
                    json_meta: [
                        [
                            {
                                key: "charset",
                                value: "utf-8",
                            },
                        ],
                    ],
                }
                let param = {}


                if (this.serverParams.ReportType == "ByMonth") {
                    param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };

                    await axios({
                        url: 'Trekking/DownloadRestrictedAreasMonthWise',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        console.log(response.data);
                        //debugger;
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'RestrictedAreasMonthWise.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
                }
                else if (this.serverParams.ReportType == "ByYear") {

                    param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ToDate: this.yearToDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                    };
                    await axios({
                        url: 'Trekking/DownloadRestrictedAreasYearWise',
                        method: 'POST',
                        data: param
                    }).then((response) => {
                        this.excelData.json_fields['Year'] = 'Year'
                        response.data.header.map(res => {
                            console.log(res.field)
                            this.excelData.json_fields[res.label] = res.field
                        })
                        this.excelData.json_fields['Total'] = 'Total'
                        this.excelData.json_data = response.data.dataList
                    })
                        .catch(err => {
                            console.log(err)
                        });

                    console.log(this.excelData.json_data);
                    return this.excelData.json_data
                }
                else if (this.serverParams.ReportType == "ByCountry") {

                    param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ToDate: this.yearToDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                        CountryName: this.serverParams.CountryName,
                    };

                    await axios({
                        url: 'Trekking/DownloadRestrictedAreasCountryWise',
                        method: 'POST',
                        responseType: 'blob',
                        data: param
                    }).then((response) => {
                        console.log(response.data);
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'RestrictedAreasCountryWise.xlsx'); //or any other extension
                        document.body.appendChild(link);
                        link.click();

                    });
                }
            },

            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                //let param = {}
                //param = {
                //    FromDate: this.serverParams.fromDate,
                //    ToDate: this.serverParams.toDate,
                //    Trekker: this.serverParams.trekker
                //};

                if (this.reportIsYearWise) {
                    this.isLoading = true;
                    this.header_title = 'By Month';
                    let param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ReportType: this.serverParams.ReportType
                    };
                    await axios({
                        url: 'Trekking/GetRestrictedAreasMonthWiseList',
                        method: 'POST',
                        data: param
                    }).then((response) => {
                        //this.excelData.json_fields['TravelMonth'] = 'TravelMonth'

                        this.rows = response.data;
                        //this.totalRecords = response.data.totalCount;
                        this.columns = [];
                        this.columns = [
                            {
                                label: 'trekkingRoute',
                                field: 'trekkingRoute'
                            },
                            {
                                label: 'january',
                                field: 'january'
                            },
                            {
                                label: 'february',
                                field: 'february'
                            }, {
                                label: 'march',
                                field: 'march'
                            }, {
                                label: 'april',
                                field: 'april'
                            }, {
                                label: 'may',
                                field: 'may'
                            }, {
                                label: 'june',
                                field: 'june'
                            }, {
                                label: 'july',
                                field: 'july'
                            }, {
                                label: 'august',
                                field: 'august'
                            }, {
                                label: 'september',
                                field: 'september'
                            }, {
                                label: 'october',
                                field: 'october'
                            }, {
                                label: 'november',
                                field: 'november'
                            }, {
                                label: 'december',
                                field: 'december'
                            }, {
                                label: 'total',
                                field: 'total'
                            }
                        ]
                        this.isLoading = false;

                        //response.data.header.map(res => {
                        //    this.excelData.json_fields[res.label] = res.field
                        //})
                        //this.excelData.json_fields['Total'] = 'Total'
                        //this.excelData.json_data = response.data.dataList
                    })
                        .catch(err => {
                            console.log(err)
                        });
                }

               
                if (this.reportIsMonthWise) {
                    this.isLoading = true;
                    this.header_title = 'By Year';
                    let param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ToDate: this.yearToDate + '-01-01',
                        ReportType: this.serverParams.ReportType
                    };
                    await axios({
                        url: 'Trekking/GetRestrictedAreasYearWiseList',
                        method: 'POST',
                        data: param
                    }).then((response) => {
                        console.log(response.data);
                        this.rows = response.data.dataList
                        this.columns = []
                        this.columns = response.data.header

                        this.columns.unshift(
                            { label: 'Year', field: 'Year' }
                        )
                        this.columns.push(
                            { label: 'Total', field: 'Total' }
                        )

                        this.isLoading = false;

                    })
                        .catch(err => {
                            console.log(err)
                        });
                }

                if (this.reportIsCountryWise) {
                    this.isLoading = true;
                    this.header_title = 'By Country';

                    let param = {
                        FromDate: this.yearFromDate + '-01-01',
                        ToDate: this.yearToDate + '-01-01',
                        ReportType: this.serverParams.ReportType,
                        CountryName: this.serverParams.CountryName
                    };
                    await axios({
                        url: 'Trekking/GetRestrictedAreasCountryWiseList',
                        method: 'POST',
                        data: param
                    }).then((response) => {

                        this.rows = response.data;
                        this.isLoading = false;
                    })
                        .catch(err => {
                            console.log(err)
                        });
                }
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage == -1 ? this.totalRecords : params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
        },
        mounted() {
            this.loadItems();
            this.loadFilterListItems();
        },
        data() {
            return {
                isLoading: false
                ,
                
                excelData: {
                    loading: false,
                    json_fields: {},
                    json_data: [],
                    json_meta: [
                        [
                            {
                                key: "charset",
                                value: "utf-8",
                            },
                        ],
                    ],
                },
                ReportTypes: [
                    {
                        id: 'ByYear', value: this.$t('year_wise')

                    },
                    {
                        id: 'ByMonth', value: this.$t('month_wise')

                    },
                    {
                        id: 'ByCountry', value: this.$t('country_wise')

                    }],
                yearFromDate: new Date().getFullYear(),
                yearToDate: new Date().getFullYear(),
                //activeComponent: "",
                //tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                responseData: [],
                rows: [],
                columns: [],
                dialogDelete: false,
                CountryNames: [],
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "TrekkingRoute",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    trekker: "",
                    gender: "",
                    ageGroup: "",
                    countryName: "",
                    trekkingRoute: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    $blue: #265b93;

    .myCustomTable {
        text-align: center;
        width: fit-content;
        min-width: 100%;

        thead, tbody {
            width: 100%;
        }

        thead {
            width: 100%;
            background-color: $blue;
            color: white;
        }

        td, th {
            padding: 1rem
        }

        border: 1px solid #eee;

        tr, td, th {
            border: 1px solid #eee;
        }
    }

    .table-container {
        width: 100%;
    }

    .headerBackground {
        background-color: $blue;
        color: white;
    }

    .sticky-table-header {
        thead {
            position: sticky;
            top: 60px;
            z-index: 1;
        }
    }

    .vgt-table {
        th {
            span {
                font-size: 12px;
                font-weight: bold;
            }
        }
    }

    .overflow-table {
        overflow-x: unset !important;
    }

    @media (max-width: 576px) {
        .table-container, .overflow-table {
            overflow-x: scroll !important;
        }
    }
</style>
